<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"

        class="multi-col-validation mt-6"
      >
        <v-row>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'SiteSettings',
  data: () => ({
    valid: true,
    form: {
      entering_level: '',
    },

    rules: {
      entering_level: [
        v => !!v || 'الاسم  مطلوب',
      ],
    },
  }),
  async mounted() {
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate() === false) {
          return
        }
        await toast.dispatch('success', 'تم التحديث بنجاح');
      } catch (error) {
        // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      }
    },
  },
}
</script>

<style scoped>

</style>
